import Head from "next/head";

const PageHead = ({ title }) => {
  const message = `${title} || JpegWriter - Image to Text Automation for Photographers`
  return (
    <>
      <Head>
        <title>{message} </title>
        <meta name="description" content="Transform your photography workflow with AI-powered content automation. Instantly turn your images into SEO-optimized blog posts, social media updates, and short videos. Test ShutterPost-AI for free and unlock effortless content creation today—no credit card required!" />
        <meta name="keywords" content="AI for photographers, Photography content automation, Image-to-text AI tool, Free AI trial for photographers, Instant content creation, SEO blog generator for photographers, Social media automation for photographers, Photography marketing AI tool, Free AI-powered content creation, JpegWriter" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
    </>
  );
};

export default PageHead;
